import React from "react";
import { Link } from "gatsby";

import MainLayout from "../layouts/MainLayout";
import Breadcrumb from "../components/common/breadcrumb";

// import Custom Components
import PageHeader from "../components/common/page-header";
import Accordion from "../components/features/accordion/accordion";
import Card from "../components/features/accordion/card";
import SEO from "../components/common/SEO";

function FAQ() {
  return (
    <MainLayout>
      <SEO title="Jimenez Store Mr. Discount - FAQ Page" />
      <div className="main">
        <h1 className="d-none">Jimenez Store Mr. Discount - FAQ Page</h1>

        <PageHeader title="F.A.Q" subTitle="Pages" />
        <Breadcrumb title="FAQ" />

        <div className="page-content">
          <div className="container">
            <p>
              Below are answers to commonly asked questions. If you don't see
              what you're looking for, please contact us . Click on the question
              to see the answer.
            </p>
            <h2 className="title text-center mb-3">Ordering</h2>

            <Accordion adClass="accordion-rounded">
              <Card
                title="Can I change my order after I place it?"
                adClass="card-box card-sm bg-light"
              >
                As we process and ship orders quickly (12-24 hours during
                business week), it is unlikely that we'll be able to make
                changes to your order after you submit it. If you would like to
                try to update your order, please contact us.
              </Card>

              <Card
                title="What payment methods do you accept?"
                adClass="card-box card-sm bg-light"
              >
                We accept all major credit cards: Discover, Visa, Mastercard,
                and American Express.
              </Card>

              <Card
                title="Is my information secure when I place an order?"
                adClass="card-box card-sm bg-light"
              >
                Yes, we use industry standard SSL encryption to protect your
                personal information during checkout and contact forms.
              </Card>

              <Card
                title="Can I place an order from outside the USA?"
                adClass="card-box card-sm bg-light"
              >
                At this time, our website only accepts orders in the USA.{" "}
              </Card>

              <Card
                title="Can I buy in bulk?"
                adClass="card-box card-sm bg-light"
              >
                Sure, we offer a huge quantity discount. Please contact us if
                you are interested in buying in bulk. We also offer truckloads
                and pallets at our facility.{" "}
              </Card>
            </Accordion>

            <h2 className="title text-center mb-3">
              Delivery & Curbside pickup
            </h2>

            <Accordion adClass="accordion-rounded">
              <Card
                title="Do you offer Delivery ? "
                adClass="card-box card-sm bg-light"
              >
                Not at the moment.
              </Card>
              <Card
                title="How Does curbside pickup works ? "
                adClass="card-box card-sm bg-light"
              >
                Curbside makes shopping quick and easy. Just place your order
                with the Curbside pickup option. We’ll notify you when your
                order is ready for pickup. Swing by the store and we’ll bring it
                to your car. Best of all, it’s completely free!{" "}
              </Card>
              <Card
                title="Who can pick up my order?"
                adClass="card-box card-sm bg-light"
              >
                You’ll be asked to provide the pickup person’s name, email
                address and phone number. They’ll need to show their ID and the
                order number during the Curbside Pickup process at the store.
                Only you — or the person you’ve told us will pick up your order
                — can pick up your items.
              </Card>
              <Card
                title="How will I know my pickup is ready?"
                adClass="card-box card-sm bg-light"
              >
                We’ll send you a text message and email when your order is ready
                for pickup.
              </Card>
              <Card
                title="When will my order be delivered after I place my order?"
                adClass="card-box card-sm bg-light"
              >
                Most orders are delivered within 24 hours during the business
                week and delivered within 1-5 days depending on delivery method
                and receiving address.{" "}
              </Card>

              <Card
                title="Can I make changes to pickups after I’ve placed them?"
                adClass="card-box card-sm bg-light"
              >
                No changes can be made once your order is placed. If you have a
                problem with an order, please email help@jimenemrdiscount.com
                for assistance.
              </Card>
              <Card
                title="How long do I have to pick up my order?"
                adClass="card-box card-sm bg-light"
              >
                Within 15 days of purchase. Contact us if you need to extend.
              </Card>
            </Accordion>
            <h2 className="title text-center mb-3">Returns</h2>
            <Accordion adClass="accordion-rounded">
              <Card
                title=" Can I return all or part of my order?"
                adClass="card-box card-sm bg-light"
              >
                Unfortunately we currently do not offer Please view our returns
                policies for additional information on returns. Just contact us
                if you need to return anything.
              </Card>{" "}
              <Card
                title="Can I return a damaged order ?"
                adClass="card-box card-sm bg-light"
              >
                Yes, we offer a 15 day return policy if your product is
                defective for in store credit. Bring your proof of purchase with
                you.
              </Card>
            </Accordion>
            <h2 className="title text-center mb-3">General Questions</h2>
            <Accordion adClass="accordion-rounded">
              <Card
                title="What are your hours of operation ? "
                adClass="card-box card-sm bg-light"
              >
                Monday - Friday: 6:00am - 5:30pm <br />
                Saturday: 10am - 4pm
                <br />
                Sunday : Closed
              </Card>{" "}
              <Card
                title=" Where are you located ? "
                adClass="card-box card-sm bg-light"
              >
                6425 English Ave, Indianapolis, IN 46219
              </Card>
            </Accordion>
          </div>
        </div>

        <div
          className="cta cta-display bg-image pt-4 pb-4"
          style={{
            backgroundImage: `url('/assets/images/backgrounds/cta/bg-7.jpg')`,
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-9 col-xl-7">
                <div
                  className={`row no-gutters flex-column'} flex-sm-row align-items-sm-center`}
                >
                  <div className="col">
                    <h3 className="cta-title text-white">
                      If You Have More Questions
                    </h3>
                    {/* <p className="cta-desc text-white">Quisque volutpat mattis eros</p> */}
                  </div>

                  <div className="col-auto">
                    <Link to={`/contact`} className="btn btn-outline-white">
                      <span>CONTACT US</span>
                      <i className="icon-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default React.memo(FAQ);
